// DESKTOP
$grid-columns: $default-grid-columns;
$grid-column-width: $default-grid-column-width;
$grid-gutter-width: $default-grid-gutter-width;

// No need for a media query here,
// this is always the base layout
body {
  &.show-responsive-identifier {
    .responsive-identifier {
      background: $xl-identifier-color;

      &:after {
        content: $xl-identifier-text;
      }
    }
  }

  &.show-grid {
    @include visualize-grid;
  }

  nav,
  header,
  footer,
  div.wrapper {
    width: calc-grid() + px;
  }

  // 2 sidebar layouts
  &.two-sidebars {
    &.columns-middle .content.column,
    &.columns-right .content.column,
    &.columns-left .content.column {
      @include span-columns(8);
    }

    &.columns-middle .sidebar.first,
    &.columns-left .sidebar.first,
    &.columns-left .sidebar.second,
    &.columns-right .sidebar.first,
    &.columns-middle .sidebar.second,
    &.columns-right .sidebar.second {
      @include span-columns(4);
    }
  }

  // 1 sidebar layouts
  &.one-sidebar {
    &.columns-right .content.column,
    &.columns-middle .content.column,
    &.columns-left .content.column {
      @include span-columns(12);
    }

    &.columns-middle .sidebar.second,
    &.columns-middle .sidebar.first,
    &.columns-left .sidebar.first,
    &.columns-left .sidebar.second {
      @include span-columns(4);
    }

    &.columns-right .sidebar.first,
    &.columns-right .sidebar.second {
      @include span-columns(4);
    }

    &.columns-right.sidebar-first,
    &.columns-middle.sidebar-second {
      .content.column {
        @include span-columns(12);
      }

      .sidebar.second {
        @include span-columns(4);
      }
    }
  }

  // no sidebar layout
  &.no-sidebars {
    &.columns-middle .content.column,
    &.columns-right .content.column,
    &.columns-left .content.column {
      @include span-columns(16);
    }
  }
}
