nav{
  display: inline-block;
}

nav ul {
  @include inline-list;
}

.header-menus{
  //border: 1px solid red;
  //padding-left: 1.1em;
  //max-width: 97%;
  //margin: 0 auto;
  margin-top: -2px;
  padding: 0 1.5%;
  nav{
    width: auto !important;
    //margin-right: 1em;
    &:first-child{
      ul > li {
        &:first-child{
          padding-left: 0;

        }

      }
    }
    ul{
      li{
        padding: 8px 14px 8px;
        display: inline-block;
        position: relative;
        //border:1px solid black;
        &:first-child{
          //padding-right: 28px;
        }
        a{
          //border: 1px solid grey;
          &:active, &.active{
            color: #b2b1b2;
          }
          text-transform: uppercase;
          font-size: 75%;
          //line-height: 80%;
          $arrow-size: 5px;
          color: black;
          &:hover{
            color: #b2b1b2;
            text-decoration: none;
          }
          &:hover:after{
            border-left: solid transparent $arrow-size;
            border-right: solid transparent $arrow-size;
            border-bottom: solid grey $arrow-size;
            bottom: -1px;
            content: " ";
            height: 0;
            left: 50%;
            margin-left: -$arrow-size;
            position: absolute;
            width: 0;

          }
        }
      }
    }
  }
}

ul.action-links{
  display: none;
  li{
    list-style: none;
    a{
      border: 1px solid;
      padding: .25em;

    }
  }
}
