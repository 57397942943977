// Ad a new icomoon character on a new line
$fonticons: ();

// For crisp icons use font-size: 16, 32, 64 etc
$fonticons: append($fonticons, status '\e619');
$fonticons: append($fonticons, error '\e61a');
$fonticons: append($fonticons, warning '\e61b');
$fonticons: append($fonticons, checked '\e00b');
$fonticons: append($fonticons, unchecked '\e00c');
$fonticons: append($fonticons, checked-dot '\e00d');
$fonticons: append($fonticons, cogs '\e900');
