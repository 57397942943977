$debug-mode: false;

// Vendor prefixes
$default-prefixes: webkit, khtml, moz, ms, o;

// Font icons using Icomoon font
$fonticons: status '\e619', error '\e61a', warning '\e61b', checked '\e00b', unchecked '\e00c', checked-dot '\e00d';

// Various arrows (Open arrows), (Closed arrows)
$fontarrows: ((down '\e607', up '\e608', left '\e616', right '\e617'), (down '\e610', up '\e60e', left '\e606', right '\e60f'));
$icon-spacing: 5px;

// Basic colors
$white: #FFFFFF;
$silver: #c0c0c0;

$page-background-color: $white;
$page-font-color: #3B3B3B;

// Grid debug colors
$pomegranate: #C0392B;
$amethyst: #9B59B6;
$belizehole: #2980B9;
$nepgritis: #27AE60;
$sunflower: #F1C40F;

$default-font-size: 16px;
$default-line-height: 24px;

// mark-tag
$mark-background-color: #ffff00;
$mark-font-color: #000000;

$border-radius: 2px;

// Inputs
$input-focus-border-color: #4169e1;
$input-border-radius: $border-radius;
$webkit-autofill-color: #F0FEFF;

// Buttons
$button-border-color: $silver;
$button-border-hover-color: #444444;
$button-font-color: darken($button-border-hover-color, 10%);
$button-border-radius: $border-radius;
$button-font-size: $default-font-size;
$button-background-color: #f5f5f5;

// Fieldset
$fieldset-border-color: $button-border-color;

// radio & checkboxes
$checked-color: $input-focus-border-color;
$check-icon-color: #808080;

// default grid values
$default-grid-columns: 16;
$default-grid-column-width: 48;
$default-grid-gutter-width: 30;

$master-context-width: $default-grid-column-width * $default-grid-columns + $default-grid-gutter-width * ($default-grid-columns - 1);

// media queries
$mobile: 'screen and (min-width: 0px) and (max-width: 767px)';
$xs: 'screen and (min-width: 0px) and (max-width: 479px)';
$s: 'screen and (min-width: 480px) and (max-width: 767px)';

$tablet: 'screen and (min-width: 768px) and (max-width: 1247px)';
$m: 'screen and (min-width: 768px) and (max-width: 1001px)';
$l: 'screen and (min-width: 1002px) and (max-width: 1247px)';

$xl: 'screen and (min-width: 1248px)';

$xs-identifier-color: $sunflower;
$xs-identifier-text: 'XS - MOBILE PORTRAIT';
$s-identifier-color: $nepgritis;
$s-identifier-text: 'S - MOBILE LANDSCAPE';
$m-identifier-color: $belizehole;
$m-identifier-text: 'M - TABLET PORTRAIT';
$l-identifier-color: $amethyst;
$l-identifier-text: 'L - TABLET LANDSCAPE';
$xl-identifier-color: $pomegranate;
$xl-identifier-text: 'XL - DESKTOP';
