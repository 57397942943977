// sass-lint:disable no-duplicate-properties, property-sort-order

// Notice the font family names, the 3 Serif and the 3 Sans names are identical.
// How does the browser know which one to take? Change the font-weight, just like
// you would do with a real font. With the values set below you can also use
// The keyword 'normal' is synonymous with '400', and 'bold' is synonymous with '700'
// There is no keyword for the lightest version unfortunately
//
// Read more about it here:
// http://www.metaltoad.com/blog/how-use-font-face-avoid-faux-italic-and-bold-browser-styles

//@font-face {
//  font-family: "Titillium";
//  src: url("../fonts/titilliumweb-light-webfont.eot");
//  src: url("../fonts/titilliumweb-light-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/titilliumweb-light-webfont.woff") format("application/font-woff"), url("../fonts/titilliumweb-light-webfont.ttf") format("truetype"), url("../fonts/titilliumweb-light-webfont.svg#titillium_weblight") format("svg");
//  font-weight: 100;
//  font-style: normal;
//}
//
//@font-face {
//  font-family: "Titillium";
//  src: url("../fonts/titilliumweb-regular-webfont.eot");
//  src: url("../fonts/titilliumweb-regular-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/titilliumweb-regular-webfont.woff") format("application/font-woff"), url("../fonts/titilliumweb-regular-webfont.ttf") format("truetype"), url("../fonts/titilliumweb-regular-webfont.svg#titillium_webregular") format("svg");
//  font-weight: 400;
//  font-style: normal;
//}
//
//@font-face {
//  font-family: "Titillium";
//  src: url("../fonts/titilliumweb-bold-webfont.eot");
//  src: url("../fonts/titilliumweb-bold-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/titilliumweb-bold-webfont.woff") format("application/font-woff"), url("../fonts/titilliumweb-bold-webfont.ttf") format("truetype"), url("../fonts/titilliumweb-bold-webfont.svg#titillium_webbold") format("svg");
//  font-weight: 700;
//  font-style: normal;
//}
//
//@font-face {
//  font-family: "Titillium";
//  src: url("../fonts/titilliumweb-lightitalic-webfont.eot");
//  src: url("../fonts/titilliumweb-lightitalic-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/titilliumweb-lightitalic-webfont.woff") format("application/font-woff"), url("../fonts/titilliumweb-lightitalic-webfont.ttf") format("truetype"), url("../fonts/titilliumweb-lightitalic-webfont.svg#titillium_weblight_italic") format("svg");
//  font-weight: 100;
//  font-style: italic;
//}
//
//@font-face {
//  font-family: "Titillium";
//  src: url("../fonts/titilliumweb-italic-webfont.eot");
//  src: url("../fonts/titilliumweb-italic-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/titilliumweb-italic-webfont.woff") format("application/font-woff"), url("../fonts/titilliumweb-italic-webfont.ttf") format("truetype"), url("../fonts/titilliumweb-italic-webfont.svg#titillium_webitalic") format("svg");
//  font-weight: 400;
//  font-style: italic;
//}
//
//@font-face {
//  font-family: "Titillium";
//  src: url("../fonts/titilliumweb-bolditalic-webfont.eot");
//  src: url("../fonts/titilliumweb-bolditalic-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/titilliumweb-bolditalic-webfont.woff") format("application/font-woff"), url("../fonts/titilliumweb-bolditalic-webfont.ttf") format("truetype"), url("../fonts/titilliumweb-bolditalic-webfont.svg#titillium_webbold_italic") format("svg");
//  font-weight: 700;
//  font-style: italic;
//}

// Icomoon
@font-face {
  font-family: "icomoon";
  src: url("../fonts/icomoon.eot");
  src: url("../fonts/icomoon.eot?#iefix") format("embedded-opentype"), url("../fonts/icomoon.woff") format("application/font-woff"), url("../fonts/icomoon.ttf") format("truetype"), url("../fonts/icomoon.svg#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
}


