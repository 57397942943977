// sass-lint:disable no-important

.tabs,
.tabs-container {
  margin: 20px 0 0;

  nav {
    width: 100%;
  }

  ul.primary {
    @extend %clearfix;
    border-bottom: 1px solid $button-border-color;
    margin: 0;

    li {
      display: inline-block;

      a {
        background: $page-background-color;
        border: 1px solid $button-border-color;
        border-bottom: 0;
        color: $button-font-color;
        display: block;
        font-size: $button-font-size;
        padding: 4px 10px;

        &:hover {
          background: $button-background-color;
        }

        &.active {
          background: $button-background-color;
          border-bottom: 0;
        }

        .element-invisible {
          position: absolute !important;
        }
      }
    }
  }
}

body.ft-tabs-rendered {
  .wrapper .tabs-container {
    display: none;
  }

  div.ft-collapsible-tabs {
    $rgba: rgba(0, 0, 0, 0.75);
    background-color: $silver;
    border-radius: 10px 0 0 10px;
    box-shadow: 0 0 10px 0 $rgba;
    height: 54px;
    position: fixed;
    right: 0;
    top: 200px;
    transition: width $transition-speed $easing;
    width: 54px;
    z-index: 99;

    .ft-tab-clicker {
      display: inline-block;
      padding: 10px;
      position: absolute;
      right: 0;
      transition: right $transition-speed $easing;

      &:before {
        @include icomoon('cogs');
        color: $page-font-color;
        cursor: pointer;
        font-size: 32px;
      }
    }

    nav {
      border-radius: 0 0 0 10px;
      margin: 0;
      overflow: hidden;
      position: absolute;
      right: -300px;
      text-align: right;
      top: 0;
      transition: right $transition-speed $easing;
      width: 300px;

      ul {
        border: 0;

        li {
          display: block;

          a {
            background-color: $silver;
            border: 0;
            color: $page-font-color;
            transition: background-color $transition-speed $easing;

            &:hover {
              background-color: $porcelain;
            }
          }
        }
      }
    }

    &.ft-tabs-open {
      width: 354px;

      .ft-tab-clicker {
        right: 300px;
      }

      nav {
        right: 0;
      }
    }
  }
}
