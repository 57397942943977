header {
  padding-top: 2.8em;
  width: 100% !important;
  .logo {
    //padding-left: 1.1em;
    max-width: 97%;
    margin: 0 auto;
  }
}

.header-menus {
  border-bottom: 1px solid #838383;
}
