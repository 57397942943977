// MOBILE LANDSCAPE

$grid-columns: 8;
$grid-column-width: 47;
$grid-gutter-width: 12;

@media #{$s} {
  body.show-responsive-identifier {
    .responsive-identifier {
      background: $s-identifier-color;

      &:after {
        content: $s-identifier-text;
      }
    }
  }

  // adaptive layout calculations
  body.layout-adaptive {
    &.show-grid {
      @include visualize-grid;
    }

    nav,
    footer,
    header,
    div.wrapper {
      width: calc-grid() + px;
    }

    // all layouts
    &.no-sidebars,
    &.one-sidebar,
    &.two-sidebars {
      &.columns-middle,
      &.columns-right,
      &.columns-left {
        .sidebar.first,
        .sidebar.second,
        .content.column {
          @include span-columns(8);
        }
      }
    }
  }

  // fluid layout calculations
  body.layout-fluid {
    &.show-grid {
      @include visualize-grid;
    }

    nav,
    header,
    footer,
    div.wrapper {
      width: calc-fluid-grid($grid-columns * 2);
    }

    // 2 sidebar layouts
    &.two-sidebars {
      .sidebar.first,
      .sidebar.second,
      .content.column {
        @include span-fluid-columns(8);
      }

      &.columns-middle {
        .sidebar.second {
          position: relative;
          right: auto;
        }
      }
    }

    // 1 sidebar layouts and no sidebar layout
    &.no-sidebars,
    &.one-sidebar {
      &.columns-right .sidebar.second,
      &.columns-right .sidebar.first,
      &.columns-right .content.column,
      &.columns-middle .content.column,
      &.columns-left .content.column,
      &.columns-middle .sidebar.second,
      &.columns-middle .sidebar.first,
      &.columns-left .sidebar.first,
      &.columns-left .sidebar.second {
        @include span-fluid-columns(8);
      }
    }
  }

}
