.webform-component--description {
  padding-bottom: 1.5em;
}

.webform-client-form {
  .webform-layout-box.equal.child-width-3 > *, .webform-layout-box.equal.child-width-2 > * {
    width: auto;
    margin-right: 3em;
  }
}

.webform-container-inline{
  label{
    display: block;
  }
}
.node-type-webform {
  .node-webform {
    .field {
      margin-bottom: 2em;
    }
    p.notice {
      font-weight: bold;
    }
    .form-type-checkbox {
      label {
        line-height: 2em;
      }
    }
    .captcha {
      margin: 3em 0;
    }
  }
}

.view .store-name {
  color: grey;
  margin-top: 1em;
  margin-bottom: 0;
  font-size: 160%;
  text-transform: uppercase;
  font-stretch: ultra-expanded;
}

.raffle {
  display: inline-block;
  padding: 1em 0 5em 0;
  vertical-align: top;
  margin-top: .5em;

  //border: 1px solid;
  width: 48%;
  min-width: 26em;
  //float: left;
  &.raffle-1 {
    //float:right;
  }
}

.raffle-spacer {
  display: inline-block;
  width: 20%;
  border: 1px solid;
}

.hidden {
  display: none;
}
